/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createMachine, assertEvent, assign } from "xstate";

const machine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QDsCWBjA1sghgWzAFkd0ALVZMAYgGUBRAGToGEAVAfQEFnmB5AVQByrANoAGALqJQABwD2sVABdUc5NJAAPRAHYAnADoAjHoCsAZiMAmPQA47YgGw6ANCACeiALSnHBx2KB5gAsprbBYrbOjgC+MW5oWLgExGQUYAZ0yEpgAE4UUIIY2PjUNPwAQoQAkhyC1cwA0oKchHTiUkgg8ooqahraCEZi5gY6OiFWYno6vkazRm6eCFYRBnrmzhHmU1a2RrZxCcXJRCTklAZFSaX8MhA4ORBUmrBKjxk4AGY5uQAUw0CAEoqIkSilzukridbvcPhAOhoespVOouoNbOZRkZfFZ5sEJqYxME9EtEPMdAZQpYQnpVmIdFYQkcQGDTqkLhk7g8VMhCjCCFQIGoMhQAG5yTAZACucJy13BYERXWRfTRoEGJMppmGjg2dOCwR2jjJCHMeiMBlMekiph0OJMwSspmCLLZpQ5UO5jwKCtOVDyuTkuQMMgANo8vsG8AZZTywH7SsrZAoUf10d4TJaLdEjUZHEaZqbVlYqZsdMFrAyC3o9HF4qy5BA4Bp3RC0pQkam1QNvEzDDmdAXLMOix5M4bjIExDYQsMdUY3QKzh2Mllfr7l13eqje0NVmMQptzDpiVZ9CZTdY-BYh87TA-nNNzEubu3OQZqhAw2Bt2n1VoiC2CMBiBDowEnjs5g6qS44IF4RjBOsiFGnqgQBIhM6voqnqXImBDek8f49hmCC2Dq6x0gcBwbMEtgTKalalvsEz0RWjgPtM2HspClyEZub6-iq3a7qRTqmuY9gGJYEx6M4dL0Y4L71kAA */
    context: {},
    id: "nicknameMachine",
    initial: "Idle",
    on: {
      SELECT_ACCOUNT: {
        actions: [
          {
            type: "assignComverseAccountToContext",
          },
          {
            type: "redirectToUpdateNicknamePage",
          },
        ],
        target: "#nicknameMachine.EnteringNickname",
      },
    },
    states: {
      EnteringNickname: {
        on: {
          SUBMIT_NICKNAME: {
            actions: {
              type: "assignNicknameToContext",
            },
            target: "UpdatingNickname",
          },
        },
      },
      Idle: {
        entry: {
          type: "redirectToLinkedAccounts",
        },
      },
      NicknameUpdated: {
        after: {
          1000: {
            actions: { type: "redirectToLinkedAccounts" },
            target: "Idle",
          },
        },
      },
      UpdatingNickname: {
        invoke: {
          id: "updateNickname",
          input: ({ context }) => {
            return {
              comverseAccountId: context.comverseAccountId,
              nickname: context.nickname,
            };
          },
          onDone: {
            actions: [
              {
                type: "refetchAccounts",
              },
            ],
            target: "NicknameUpdated",
          },
          onError: {
            actions: {
              type: "displayErrorMessage",
            },
            target: "EnteringNickname",
          },
          src: "updateNickname",
        },
      },
    },
    types: {
      context: {} as {
        nickname?: string;
        comverseAccountId?: string;
      },
      events: {} as
        | {
            type: "SELECT_ACCOUNT";
            data: { comverseAccountId: string; nickname: string };
          }
        | { type: "SUBMIT_NICKNAME"; data: string }
        | { type: "xstate.done.actor.updateNickname" },
    },
  },
  {
    actions: {
      assignComverseAccountToContext: assign({
        comverseAccountId: ({ event }) => {
          assertEvent(event, "SELECT_ACCOUNT");

          return event.data.comverseAccountId;
        },
        nickname: ({ event }) => {
          assertEvent(event, "SELECT_ACCOUNT");

          return event.data.nickname;
        },
      }),
      assignNicknameToContext: assign({
        nickname: ({ event }) => {
          assertEvent(event, "SUBMIT_NICKNAME");

          return event.data;
        },
      }),
    },
    actors: {
      updateNickname: createMachine({
        /* ... */
      }),
    },
  },
);

export default machine;
