import React, { useState } from "react";
import { createPortal } from "react-dom";
import Link from "next/link";
import {
  FaAngleRight,
  IconContactUs,
  IconDocument,
  IconDocumentText,
  IconAccountSetting,
  IconSignOutRight,
  Button,
  FaCloseRounded,
} from "@jt/ui";
import LogoutButton from "../LogoutButton";
import type { Route } from "nextjs-routes";
import useAuth from "@/useAuth";
import config from "@/config";
import ComverseAccountAndServicesMachineContext from "@/components/machines/comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";
import { isPrePaidAccount } from "@/components/machines/comverseAccountAndServicesMachine/typeGuards";

/* getFeedback need both allow-same-origin allow-scripts, but eslint rule does not allow, therefore disable this rule */
/* eslint-disable react/iframe-missing-sandbox */

interface SettingItemProperties {
  to: Route | string;
  title: string;
  icon: React.ReactNode;
  onClose?: () => void;
}

interface UserMenuProperties {
  isFeedbackDisabled?: boolean;
  onClose?: () => void;
}

function SettingItem({ to, title, icon, onClose }: SettingItemProperties) {
  const linkContent = (
    <div className="mb-2 flex items-center justify-between rounded bg-white px-4 py-2">
      <div className="flex items-center">
        <div className="mr-4 text-primary">{icon}</div>
        <span className="font-semibold">{title}</span>
      </div>
      <FaAngleRight />
    </div>
  );

  const isExternalLink = typeof to === "string";

  return (
    <div>
      {isExternalLink ? (
        <a
          className="cursor-pointer"
          href={to}
          onClick={onClose}
          rel="noopener noreferrer"
          target="_blank"
        >
          {linkContent}
        </a>
      ) : (
        <Link href={to} onClick={onClose}>
          {linkContent}
        </Link>
      )}
    </div>
  );
}

function UserMenu({ isFeedbackDisabled = false, onClose }: UserMenuProperties) {
  const { user, isAuthenticated } = useAuth();

  const servicesState = ComverseAccountAndServicesMachineContext.useSelector(
    (snapshot) => {
      return snapshot;
    },
  );
  const { comverseAccounts, selectedComverseAccountId } = servicesState.context;

  const [canFeedback, setCanFeedback] = useState(false);

  const selectedComverseAccount = comverseAccounts?.find(
    (account) => account.comverseAccountId === selectedComverseAccountId,
  );

  return (
    <div className="font-normal">
      <SettingItem
        icon={<IconAccountSetting />}
        onClose={onClose}
        title="Account Settings"
        to={{ pathname: "/user/settings" }}
      />

      <SettingItem
        icon={<IconContactUs />}
        onClose={onClose}
        title="Contact Us"
        to={{ pathname: "/help" }}
      />
      <SettingItem
        icon={<IconDocumentText />}
        onClose={onClose}
        title="Privacy Statement"
        to="https://www.jtglobal.com/global/privacy-policy/"
      />
      <SettingItem
        icon={<IconDocument />}
        onClose={onClose}
        title="Terms and Conditions"
        to="https://www.jtglobal.com/global/terms-conditions/"
      />
      {isFeedbackDisabled ? null : (
        <div className="my-10 flex flex-col items-center justify-center gap-6 text-center">
          <p className="text-sm">How are you getting on with our new app?</p>
          <Button
            onClick={() => {
              setCanFeedback(true);
            }}
          >
            Send Feedback
          </Button>
        </div>
      )}
      {canFeedback
        ? createPortal(
            <div className="fixed left-0 top-0 z-50 flex size-full items-center justify-center">
              <div
                aria-hidden="true"
                className="absolute left-0 top-0 z-10 size-full bg-black/50"
                onClick={() => {
                  setCanFeedback(false);
                }}
              />
              <div className="relative z-20 h-[70vh] w-full max-w-[600px] sm:h-[500px]">
                <iframe
                  className="size-full"
                  src="https://jtglobal.getfeedback.com/e/3g05Lk8E"
                  title="Feedback"
                />
                <div
                  aria-hidden="true"
                  className="absolute -top-6 right-2 cursor-pointer text-white sm:right-6 sm:top-4"
                  onClick={() => {
                    setCanFeedback(false);
                  }}
                >
                  <FaCloseRounded />
                </div>
              </div>
            </div>,
            document.body,
          )
        : null}
      {isAuthenticated && user ? (
        <>
          <div
            aria-hidden
            className="flex cursor-pointer items-center justify-between rounded bg-white p-4 hover:text-jt-teal"
          >
            <div className="mr-4 text-primary">
              <IconSignOutRight />
            </div>
            <LogoutButton
              className="grow font-semibold"
              color="secondary"
              isLink
            />
            <FaAngleRight />
          </div>
          <p className="my-4 px-4 text-sm">Logged in as: {user.username}</p>
          {selectedComverseAccount === undefined ? null : (
            <>
              <p
                className="mb-4 px-4 text-sm"
                data-cy="SelectedAccountOrService"
              >{`${
                isPrePaidAccount(selectedComverseAccount)
                  ? `Mobile Number: ${selectedComverseAccount.phoneNumber ?? ""}`
                  : `Account Number: ${selectedComverseAccount.comverseAccountId}`
              }`}</p>
              {comverseAccounts && comverseAccounts.length > 1 ? (
                <div className="mb-4 flex flex-col items-center justify-center text-center">
                  <Link href="/user/settings/linked-accounts" onClick={onClose}>
                    <Button data-cy="SwitchAccountButton">
                      Switch Account
                    </Button>
                  </Link>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : null}

      <p className="mt-8 px-4 text-xs">
        {`JT App ${config.app.version} `}
        Copyright {new Date().getFullYear()}
      </p>
    </div>
  );
}

export default UserMenu;
