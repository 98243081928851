import type { JSX } from "react";
import * as Sentry from "@sentry/nextjs";
import Template, { TemplateProvider } from "./layouts/Template";
import { ComverseAccountAndServicesMachineLogicComponent } from "./machines/comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";
import { TopupMachineContextInjector } from "@/components/machines/topupMachine/TopupMachineContext";
import useAuth from "@/useAuth";
import { NicknameMachineContextInjector } from "@/components/machines/nicknameMachine/NicknameMachineContext";
import { LinkComverseAccountContextInjector } from "@/components/machines/linkComverseAccountMachine/LinkComverseAccountMachineContext";

function AuthenticatedMyJtApp({ children }: { children: JSX.Element }) {
  const { user } = useAuth();

  Sentry.setUser({
    // No email to avoid tracking personal data
    // email: user?.username ?? "UNAUTHENTICATED_USER",
    id: user?.localAccountId ?? "UNAUTHENTICATED_USER",
  });

  return (
    <ComverseAccountAndServicesMachineLogicComponent>
      <LinkComverseAccountContextInjector>
        <NicknameMachineContextInjector>
          <TopupMachineContextInjector>
            <TemplateProvider>
              <Template>{children}</Template>
            </TemplateProvider>
          </TopupMachineContextInjector>
        </NicknameMachineContextInjector>
      </LinkComverseAccountContextInjector>
    </ComverseAccountAndServicesMachineLogicComponent>
  );
}

export default AuthenticatedMyJtApp;
