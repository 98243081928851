import { Capacitor } from "@capacitor/core";
import type { OtherThirdParties } from "@/config/configSchema";
import logger from "@/logger";
import env from "@/config/environmentVariables";

function getOtherThirdPartiesConfig(): OtherThirdParties {
  let gaMeasurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB;
  // Detect the platform
  const platform = Capacitor.getPlatform();

  switch (platform) {
    case "web": {
      gaMeasurementId = env.NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB;
      break;
    }

    case "android": {
      gaMeasurementId = env.NEXT_PUBLIC_GA_MEASUREMENT_ID_ANDROID;
      break;
    }

    case "ios": {
      gaMeasurementId = env.NEXT_PUBLIC_GA_MEASUREMENT_ID_IOS;
      break;
    }

    default: {
      gaMeasurementId = env.NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB;
      logger.warn("Unrecognized platform:", platform);
    }
  }

  return {
    gaMeasurementId,
    talkativeWidgetId: env.NEXT_PUBLIC_TALKATIVE_ID,
  };
}

export default getOtherThirdPartiesConfig;
