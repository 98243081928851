import { useQueryClient } from "@tanstack/react-query";
import type { ReactNode, JSX } from "react";
import { fromPromise } from "xstate";
import { useRouter } from "next/router";
import { createActorContext } from "@xstate/react";
import ComverseAccountAndServicesMachineContext from "../comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";
import linkComverseAccountMachine from "./linkComverseAccountMachine";
import {
  linkComverseAccountMutation,
  linkComverseAccountResendPinMutation,
  validatePinMutation,
} from "./linkComverseAccountMutation";
import useAuth from "@/useAuth";
import { useGraphQLContext } from "@/components/contexts/GraphQLContext";
import { LinkAccountProblemCode } from "@/__generated__/graphql";
import { useMachineUtil } from "@/components/contexts/MachineUtil";

const LinkComverseAccountMachineContext = createActorContext(
  linkComverseAccountMachine,
);

interface LinkAccountInput {
  phoneNumber: string;
  enteredPin: string;
}

function LinkComverseAccountMachineLogicComponent({
  children,
}: {
  children: ReactNode;
}) {
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const { graphqlClient } = useGraphQLContext();
  const queryClient = useQueryClient();
  const { inspect } = useMachineUtil();

  const comverseAccountAndServicesActor =
    ComverseAccountAndServicesMachineContext.useActorRef();

  const linkComverseAccountMachineImplementation =
    linkComverseAccountMachine.provide({
      actions: {
        navigateAfterLinkingFailure: () => {
          if (isAuthenticated) {
            void router.replace("/user/dashboard");
          } else {
            void router.replace("/");
          }
        },

        navigateToAccountSelect: () => {
          void router.replace("/user/account-select");
        },

        navigateToEnterNickname: () => {
          void router.replace("/user/link-account/enter-nickname");
        },

        navigateToEnterNumber: () => {
          void router.replace("/user/link-account/enter-number");
        },
        navigateToEnterPin: () => {
          void router.replace("/user/link-account/enter-pin");
        },
        refetchAccounts: () => {
          comverseAccountAndServicesActor.send({
            type: "REFETCH_ACCOUNTS",
          });
        },
      },
      actors: {
        validatePhoneNumberAndInitiateLinkAccount: fromPromise(
          async ({ input }: { input: LinkAccountInput }) => {
            // user may retry same phone number, so invalidate query to make sure it will trigger query again
            void queryClient.invalidateQueries({ queryKey: ["linkAccount"] });
            const data = await queryClient.fetchQuery({
              queryFn: async () => {
                return graphqlClient.request(linkComverseAccountMutation, {
                  phoneNumber: input.phoneNumber,
                });
              },
              queryKey: ["linkAccount", input.phoneNumber],
            });

            return data;
          },
        ),
        validatePhoneNumberWithRequestPinResend: fromPromise(
          async ({ input }: { input: LinkAccountInput }) => {
            // user may retry same phone number, so invalidate query to make sure it will trigger query again
            void queryClient.invalidateQueries({
              queryKey: ["requestPinResend"],
            });
            const data = await queryClient.fetchQuery({
              queryFn: async () => {
                return graphqlClient.request(
                  linkComverseAccountResendPinMutation,
                  {
                    phoneNumber: input.phoneNumber,
                  },
                );
              },
              queryKey: ["requestPinResend", input.phoneNumber],
            });

            if (
              data.linkAccountResendPin.__typename ===
              "LinkAccountPinResendSuccess"
            ) {
              return data;
            } else {
              throw new Error(data.linkAccountResendPin.message);
            }
          },
        ),
        validatePinAndConfirmLinkAccount: fromPromise(
          async ({ input }: { input: LinkAccountInput }) => {
            // user may retry same phone number and PIN, so invalidate query to make sure it will trigger query again
            void queryClient.invalidateQueries({
              queryKey: ["validatePin"],
            });
            const data = await queryClient.fetchQuery({
              queryFn: async () => {
                return graphqlClient.request(validatePinMutation, {
                  phoneNumber: input.phoneNumber,
                  pinInput: input.enteredPin,
                });
              },
              queryKey: ["validatePin", input.phoneNumber, input.enteredPin],
            });

            if (
              data.validatePin.__typename === "LinkAccountProblem" &&
              data.validatePin.code === "TOO_MANY_PIN_ATTEMPS"
            ) {
              throw new Error(data.validatePin.message);
            } else {
              void queryClient.invalidateQueries({
                queryKey: ["authAccounts"],
              });

              return data;
            }
          },
        ),
      },
    });

  return (
    <LinkComverseAccountMachineContext.Provider
      logic={linkComverseAccountMachineImplementation}
      options={{
        input: {
          enteredPin: "",
          phoneNumber: "",
        },
        inspect,
      }}
    >
      {children}
    </LinkComverseAccountMachineContext.Provider>
  );
}

function LinkComverseAccountContextInjector({
  children,
}: {
  children: JSX.Element;
}) {
  const router = useRouter();

  if (!router.pathname.includes("link-account")) {
    return children;
  }

  return (
    <LinkComverseAccountMachineLogicComponent>
      {children}
    </LinkComverseAccountMachineLogicComponent>
  );
}

function checkIfAccountIsOnLinkingState(errorCode: LinkAccountProblemCode) {
  return (
    errorCode === LinkAccountProblemCode.AlreadyLinkingByBillingEmail ||
    LinkAccountProblemCode.AlreadyLinkingBySms
  );
}

export {
  checkIfAccountIsOnLinkingState,
  LinkComverseAccountMachineContext,
  LinkComverseAccountContextInjector,
};
