import { useState } from "react";
import type { SyntheticEvent } from "react";
import Link from "next/link";
import { FaChevronDown } from "@jt/ui";
import type { Route } from "nextjs-routes";
import useServicesConfiguration from "@/components/machines/comverseAccountAndServicesMachine/servicesConfigurationHook";
import type { FolderItemProperties } from "@/menu";
import { headerMenus, postpaidMenus, prepaidMenus } from "@/menu";

interface MenuClassNameProperties {
  className?: string;
  itemClassName?: string;
  linkClassName?: string;
  onClose?: () => void;
}

interface MenuWithArrowProperties
  extends FolderItemProperties,
    MenuClassNameProperties {
  submenu?: Array<FolderItemProperties>;
  isRoot: boolean;
  isAlwaysExpanded: boolean;
}

interface MenuAndSubmenuProperties extends MenuClassNameProperties {
  data: Array<FolderItemProperties>;
  isRoot: boolean;
  isAlwaysExpanded: boolean;
}

// MenuAndSubmenu and MenuWithArrow used each other, cannot define one before another
/* eslint-disable @typescript-eslint/no-use-before-define */
function MenuAndSubmenu({
  data,
  className = "",
  itemClassName = "",
  linkClassName = "",
  isRoot,
  isAlwaysExpanded,
  onClose,
}: MenuAndSubmenuProperties) {
  return (
    <ul className={className}>
      {data.map(({ label, path, isExternalLink, submenu }, index) => (
        <li
          className={`${
            isRoot && index === 0 ? "" : "group"
          } relative ${itemClassName}`}
          key={`menuItem-${label}`}
        >
          <MenuWithArrow
            isAlwaysExpanded={isAlwaysExpanded}
            isExternalLink={isExternalLink}
            isRoot={isRoot}
            itemClassName={itemClassName}
            label={label}
            linkClassName={linkClassName}
            onClose={onClose}
            path={path}
            submenu={submenu}
          />
        </li>
      ))}
    </ul>
  );
}

function MenuWithArrow({
  label,
  path,
  isExternalLink = false,
  submenu,
  className = "",
  itemClassName = "",
  linkClassName = "",
  isRoot,
  isAlwaysExpanded,
  onClose,
}: MenuWithArrowProperties) {
  const [isOpen, setIsOpen] = useState(false);

  function onLocalClick(event: SyntheticEvent) {
    if (window.innerWidth < 767) {
      setIsOpen(!isOpen);
      event.preventDefault();
      event.stopPropagation();
    } else {
      onMenuItemClick();
    }
  }

  function onMenuItemClick() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div className={className}>
      <div
        className={`flex items-center justify-between ${
          isRoot ? "pr-2 lg:pr-5" : ""
        }`}
      >
        <Link
          className={`${
            isRoot ? "md:py-7" : "w-full text-left md:pb-3"
          } ${linkClassName}`}
          href={path as Route}
          onClick={
            submenu && submenu.length > 0 && !isAlwaysExpanded
              ? onLocalClick
              : onMenuItemClick
          }
          rel="noopener noreferrer"
          target={isExternalLink ? "_blank" : undefined}
        >
          {label}
        </Link>
        {submenu && submenu.length > 0 && !isAlwaysExpanded ? (
          <FaChevronDown
            className={`transition-all ${isOpen ? "rotate-180" : ""} md:hidden`}
          />
        ) : null}
      </div>
      {submenu ? (
        <MenuAndSubmenu
          className={`${
            isOpen || !isRoot ? "" : "hidden"
          } ml-4 mr-2 whitespace-nowrap text-left md:absolute md:-left-4 md:ml-auto md:hidden md:bg-white md:px-4 md:drop-shadow-md md:group-hover:block`}
          data={submenu}
          isAlwaysExpanded
          isRoot={false}
          itemClassName={itemClassName}
          linkClassName={linkClassName}
          onClose={onClose}
        />
      ) : null}
    </div>
  );
}

function MenuItem({
  className = "",
  itemClassName = "",
  linkClassName = "",
  onClose,
}: MenuClassNameProperties) {
  const headerMenuWithMyJTMenus: Array<FolderItemProperties> = headerMenus;
  const accountType = useServicesConfiguration();
  const isPrepaidOnly =
    accountType === "MultiplePrepaid" || accountType === "SinglePrepaid";

  headerMenuWithMyJTMenus[0].submenu = isPrepaidOnly
    ? prepaidMenus
    : postpaidMenus;

  return (
    <MenuAndSubmenu
      className={className}
      data={headerMenuWithMyJTMenus}
      isAlwaysExpanded={false}
      isRoot
      itemClassName={itemClassName}
      linkClassName={linkClassName}
      onClose={onClose}
    />
  );
}
export default MenuItem;
