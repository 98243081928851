import { type ReactElement } from "react";
import { useRouter } from "next/router";
import { useQueryClient } from "@tanstack/react-query";
import type { AnyEventObject } from "xstate";
import { fromPromise, fromCallback, assign, assertEvent } from "xstate";
import { createActorContext } from "@xstate/react";
import type { Accounts } from "./types";
import useAuth from "@/useAuth";
import { useGraphQLContext } from "@/components/contexts/GraphQLContext";
import {
  accountsQuery,
  servicesQuery,
} from "@/components/machines/comverseAccountAndServicesMachine/serviceAndAccountQueries";
import { checkIsNonEmptyString } from "@/typeGuards";
import { useMachineUtil } from "@/components/contexts/MachineUtil";
import comverseAccountAndServicesMachine from "@/components/machines/comverseAccountAndServicesMachine/comverseAccountAndServicesMachine";
import { AccountType } from "@/__generated__/graphql";

const ComverseAccountAndServicesMachineContext = createActorContext(
  comverseAccountAndServicesMachine,
);

function ComverseAccountAndServicesMachineLogicComponent({
  children,
}: {
  children: ReactElement;
}) {
  const { graphqlClient } = useGraphQLContext();
  const queryClient = useQueryClient();
  const { user, isLoading } = useAuth();
  const { inspect } = useMachineUtil();
  const router = useRouter();

  if (isLoading) return children;

  const isEsimFlow = router.pathname.toLocaleLowerCase().includes("esim");
  const initialServiceId = new URL(location.href).searchParams.get("serviceId");

  const manageComverseAccountAndServicesMachineImplementation =
    comverseAccountAndServicesMachine.provide({
      actions: {
        assignInitialServiceToContext: assign({
          selectedService: ({ event, context }) => {
            assertEvent(event, ["xstate.done.actor.getComverseServices"]);

            if (initialServiceId !== null) {
              return (
                context.servicesOnSelectedComverseAccountPlusPrepaid?.find(
                  (service) => service.comverseServiceId === initialServiceId,
                ) ?? context.servicesOnSelectedComverseAccountPlusPrepaid?.[0]
              );
            }

            const comverseAccount = context.comverseAccounts?.find(
              (account) =>
                account.comverseAccountId === context.selectedComverseAccountId,
            );

            if (comverseAccount?.type === AccountType.Prepaid) {
              const foundService =
                context.servicesOnSelectedComverseAccountPlusPrepaid?.find(
                  (service) =>
                    service.phoneNumber === comverseAccount.phoneNumber,
                );

              if (foundService !== undefined) {
                return foundService;
              }
            }

            return context.servicesOnSelectedComverseAccountPlusPrepaid?.[0];
          },
        }),
        redirectToLinkAccountPage: () => {
          if (
            isEsimFlow ||
            router.pathname.includes("link-account") ||
            router.pathname.includes("settings")
          )
            return;

          void router.replace("/user/link-account/enter-number");
        },
        redirectToSelectAccountPage: () => {
          if (isEsimFlow || router.pathname.includes("link-account")) return;

          const url = router.asPath;
          const currentPathname = router.pathname;
          const targetPathname = "/user/account-select";

          // Prevent redirection if already on the target page or if redirection has already occurred
          if (currentPathname === targetPathname) {
            return;
          }

          const isRedirectUrl = url.toLocaleLowerCase().includes("redirect=");
          const redirectUrl = isRedirectUrl
            ? url.split("?redirect=").at(-1)
            : "";

          if (checkIsNonEmptyString(redirectUrl)) {
            void router.replace({
              pathname: targetPathname,
              query: { redirectUrl },
            });
          } else {
            void router.replace(targetPathname);
          }
        },
      },
      actors: {
        getComverseAccounts: fromCallback(({ sendBack }) => {
          if (user === null) {
            return;
          }

          void queryClient.invalidateQueries({
            queryKey: ["authAccounts", "authServices"],
          });

          void queryClient
            .fetchQuery({
              queryFn: async () => {
                return graphqlClient.request(accountsQuery);
              },
              queryKey: ["authAccounts", user.localAccountId],
              staleTime: 0,
            })
            // eslint-disable-next-line promise/prefer-await-to-then
            .then(({ accounts }) => {
              const filteredAccounts = accounts.filter(
                (account) => account.disconnected !== true,
              );

              const hasDisconnectedAccounts =
                filteredAccounts.length !== accounts.length;

              if (filteredAccounts.length === 0) {
                sendBack({
                  data: { hasDisconnectedAccounts },
                  type: "NO_ACCOUNTS",
                });
              }

              sendBack({
                data: { accounts: filteredAccounts, hasDisconnectedAccounts },
                type: "HAS_ACCOUNTS",
              });

              return filteredAccounts;
            });
        }),
        getComverseServices: fromPromise(
          async ({
            input,
          }: {
            input: { selectedComverseAccountId: string };
          }) => {
            const { selectedComverseAccountId } = input;

            const data = await queryClient.fetchQuery({
              queryFn: async () => {
                return graphqlClient.request(servicesQuery, {
                  comverseAccountId: selectedComverseAccountId,
                });
              },
              queryKey: [
                "authServices",
                user?.localAccountId,
                selectedComverseAccountId,
              ],
            });

            return data.services;
          },
        ),
        getServiceConfiguration: fromCallback(
          ({
            input,
            sendBack,
          }: {
            input: {
              comverseAccounts?: Accounts;
              selectedComverseAccountId?: string;
            };
            sendBack: (event: AnyEventObject) => void;
          }) => {
            const { comverseAccounts, selectedComverseAccountId } = input;

            if (
              comverseAccounts === undefined ||
              selectedComverseAccountId === undefined
            ) {
              return;
            }

            const postpaidAccountCount = comverseAccounts.filter(
              ({ type }) => type === AccountType.Postpaid,
            ).length;
            const prepaidAccountCount = comverseAccounts.filter(
              ({ type }) => type === AccountType.Prepaid,
            ).length;

            const selectedComverseAccountType = comverseAccounts.find(
              ({ comverseAccountId }) =>
                comverseAccountId === selectedComverseAccountId,
            )?.type;

            switch (true) {
              case selectedComverseAccountType === AccountType.Prepaid &&
                prepaidAccountCount === 1: {
                sendBack({
                  type: "SINGLE_PREPAID",
                });
                break;
              }

              case selectedComverseAccountType === AccountType.Prepaid &&
                prepaidAccountCount > 1: {
                sendBack({
                  type: "MULTIPLE_PREPAID",
                });
                break;
              }

              case selectedComverseAccountType === AccountType.Postpaid &&
                postpaidAccountCount === 1 &&
                prepaidAccountCount === 0: {
                sendBack({
                  type: "ONLY_POSTPAID",
                });
                break;
              }

              case selectedComverseAccountType === AccountType.Postpaid &&
                postpaidAccountCount > 0 &&
                prepaidAccountCount > 0: {
                sendBack({
                  type: "PRE_AND_POST_PAID",
                });
                break;
              }

              default: {
                break;
              }
            }
          },
        ),
      },
      guards: {
        isEsimFlow() {
          return isEsimFlow;
        },
      },
    });

  return (
    <ComverseAccountAndServicesMachineContext.Provider
      logic={manageComverseAccountAndServicesMachineImplementation}
      options={{
        inspect,
      }}
    >
      {children}
    </ComverseAccountAndServicesMachineContext.Provider>
  );
}

export { ComverseAccountAndServicesMachineLogicComponent };
export default ComverseAccountAndServicesMachineContext;
