import { MsalProvider } from "@azure/msal-react";
import { Error } from "@jt/ui";
import { ErrorBoundary } from "@sentry/nextjs";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { ReactNode } from "react";
import { GraphQLProvider } from "./GraphQLContext";
import { MachineUtilProvider } from "./MachineUtil";
import { JtIdMachineLogicComponent } from "@/components/machines/jtIdMachine/JtIdMachineContext";
import AppAnalytics from "@/components/AppAnalytics";
import { CookiesProvider } from "@/components/machines/cookiesMachine/CookiesMachineContext";
import queryClient from "@/queryClient";
import msalInstance from "@/msalInstance";
import { ToastProvider } from "@/components/contexts/toast";

function handleLoginClick() {
  if (msalInstance.getActiveAccount()) {
    void msalInstance.logoutRedirect();
  } else {
    window.location.href = "/";
  }
}

function RootLevelContextsContainer({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary
      fallback={<Error isInline onLoginClick={handleLoginClick} />}
    >
      <ToastProvider>
        <MsalProvider instance={msalInstance}>
          <GraphQLProvider>
            <MachineUtilProvider>
              <JtIdMachineLogicComponent>
                <CookiesProvider>
                  <AppAnalytics />
                  <QueryClientProvider client={queryClient}>
                    {children}
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </CookiesProvider>
              </JtIdMachineLogicComponent>
            </MachineUtilProvider>
          </GraphQLProvider>
        </MsalProvider>
      </ToastProvider>
    </ErrorBoundary>
  );
}

export default RootLevelContextsContainer;
