import { gql } from "@/__generated__";

const accountsQuery = gql(`
  query AuthAccounts {
    accounts {
      __typename
      id
      comverseAccountId
      comverseServiceId
      phoneNumber
      type
      nickname
      disconnected
    }
  }
`);

const servicesQuery = gql(`
  query AuthServices($comverseAccountId: ComverseId!) {
    services(comverseAccountId: $comverseAccountId) {
      __typename
      id
      phoneNumber
      comverseAccountId
      comverseServiceId
    }
  }
`);

export { accountsQuery, servicesQuery };
