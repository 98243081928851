import { useQueryClient } from "@tanstack/react-query";
import { useEffect, type JSX } from "react";
import type { AnyEventObject } from "xstate";
import { assertEvent, fromCallback, fromPromise } from "xstate";
import { useRouter } from "next/router";
import { createActorContext } from "@xstate/react";
import { CircularProgress } from "@jt/ui";
import useServicesConfiguration from "../comverseAccountAndServicesMachine/servicesConfigurationHook";
import topupMachine from "./topupMachine";
import { prepaidServiceQuery, topupOffersQuery } from "./topupQueries";
import { useGraphQLContext } from "@/components/contexts/GraphQLContext";
import ComverseAccountAndServicesMachineContext from "@/components/machines/comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";
import { isSelectedServicePrepaid } from "@/components/pages/user/manage/services/typeGuards";
import { useMachineUtil } from "@/components/contexts/MachineUtil";
import { useToast } from "@/components/contexts/toast";
import logger from "@/logger";

const TopupMachineContext = createActorContext(topupMachine);

function TopupMachineLogicComponent({ children }: { children: JSX.Element }) {
  const { graphqlClient } = useGraphQLContext();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { inspect } = useMachineUtil();
  const { showToast } = useToast();
  const accountType = useServicesConfiguration();

  const comverseAccountAndServicesState =
    ComverseAccountAndServicesMachineContext.useSelector(
      (snapshot) => snapshot.context,
    );
  const comverseAccountAndServicesActor =
    ComverseAccountAndServicesMachineContext.useActorRef();

  const { selectedService, servicesOnSelectedComverseAccountPlusPrepaid } =
    comverseAccountAndServicesState;

  // If we're a PreAndPostPaid user and we're going somewhere other than topup pages we set the user back to their postpaid service

  function handleRouteChange(route: string) {
    if (
      accountType === "PreAndPostPaid" &&
      !route.includes("/user/manage/top-up") &&
      servicesOnSelectedComverseAccountPlusPrepaid
    ) {
      comverseAccountAndServicesActor.send({
        // eslint-disable-next-line array-plural/array-plural
        data: servicesOnSelectedComverseAccountPlusPrepaid[0].comverseServiceId,
        type: "SELECT_SERVICE",
      });
    }
  }

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  const manageTopupMachineImplementation = topupMachine.provide({
    actions: {
      redirectToCheckout: () => {
        void router.replace("/user/manage/top-up/checkout");
      },
      redirectToOtherNumber: () => {
        void router.replace("/user/manage/top-up/other-number");
      },
      redirectToTopup: () => {
        void router.replace("/user/manage/top-up");
      },
      showError: ({ event }) => {
        assertEvent(event, ["xstate.error.actor", "ERROR"]);

        logger.error(event.output);

        showToast({
          message: event.output.message,
        });
      },
    },
    actors: {
      checkPrepaidNumberExists: fromCallback(
        ({
          input,
          sendBack,
        }: {
          input: { phoneNumber: string };
          sendBack: (event: AnyEventObject) => void;
        }) => {
          void (async () => {
            const data = await graphqlClient
              .request(prepaidServiceQuery, {
                phoneNumber: input.phoneNumber,
              })
              .catch((error: Error) => {
                logger.error(error);

                sendBack({
                  output: new Error("Failed to check phone number"),
                  type: "ERROR",
                });
              });

            if (!data) return;

            if (!data.prepaidServiceExists) {
              sendBack({
                output: new Error("Could not find service"),
                type: "ERROR",
              });

              return;
            }

            const foundService =
              servicesOnSelectedComverseAccountPlusPrepaid?.find(
                (service) => service.phoneNumber === input.phoneNumber,
              );

            if (foundService) {
              comverseAccountAndServicesActor.send({
                data: foundService.comverseServiceId,
                type: "SELECT_SERVICE",
              });
            }

            sendBack({ data: input.phoneNumber, type: "SELECT_SERVICE" });

            void router.replace("/user/manage/top-up");
          })();
        },
      ),
      getTopupOffers: fromPromise(async () => {
        try {
          const data = await queryClient.fetchQuery({
            queryFn: async () => {
              return graphqlClient.request(topupOffersQuery);
            },
            queryKey: ["topupoffers"],
          });

          return data;
        } catch (error) {
          logger.error(error);

          throw new Error("Failed to fetch topup offers");
        }
      }),
    },
  });

  const firstPrepaid = servicesOnSelectedComverseAccountPlusPrepaid?.find(
    (service) => service.__typename === "PrePaidMobileService",
  );

  let initialPhoneNumberForTopupMachine: string | undefined =
    firstPrepaid?.phoneNumber;

  // If the selected service is a prepaid service, then we want to start with that phone number
  if (selectedService && isSelectedServicePrepaid(selectedService)) {
    initialPhoneNumberForTopupMachine = selectedService.phoneNumber;
  }

  // Get the service from our linked services that matches the initial phone number
  const foundService = servicesOnSelectedComverseAccountPlusPrepaid?.find(
    (service) => service.phoneNumber === initialPhoneNumberForTopupMachine,
  );

  // If we found a service that matches the initial phone number, then we want to set that service as the global selected service
  useEffect(() => {
    if (foundService) {
      comverseAccountAndServicesActor.send({
        data: foundService.comverseServiceId,
        type: "SELECT_SERVICE",
      });
    }
  }, []);

  // If we refresh the page and the user account machine is not done loading then we end up in a situation where you get redirected
  // to other topup even though you have a prepaid service linked.

  if (
    servicesOnSelectedComverseAccountPlusPrepaid === undefined ||
    selectedService === undefined
  ) {
    return <CircularProgress />;
  }

  return (
    <TopupMachineContext.Provider
      logic={manageTopupMachineImplementation}
      options={{
        input: {
          phoneNumber: initialPhoneNumberForTopupMachine,
        },
        inspect,
      }}
    >
      {children}
    </TopupMachineContext.Provider>
  );
}

function TopupMachineContextInjector({ children }: { children: JSX.Element }) {
  const router = useRouter();

  const topupPages = [
    "/user/manage/top-up",
    "/user/manage/top-up/checkout",
    "/user/manage/top-up/other-number",
  ];

  return topupPages.includes(router.pathname) ? (
    <TopupMachineLogicComponent>{children}</TopupMachineLogicComponent>
  ) : (
    children
  );
}

export {
  TopupMachineContext,
  TopupMachineContextInjector,
  TopupMachineLogicComponent,
};
