import { gql } from "@/__generated__";

const linkComverseAccountMutation = gql(`
mutation LinkComverseAccount($phoneNumber: String!) {
    linkAccount(phoneNumber: $phoneNumber) {
      ... on LinkAccountSuccess {
        __typename
        billingEmail
        isSuccess
        message
        pinSendMethod
      }
      ... on LinkAccountProblem {
        __typename
        code
        billingEmail
        isSuccess
        invalidInputs {
          message
          field
        }
        message
      }
    }
  }
`);

const linkComverseAccountResendPinMutation = gql(`
mutation LinkComverseAccountResendPin($phoneNumber: String!) {
    linkAccountResendPin(phoneNumber: $phoneNumber) {
      ... on LinkAccountPinResendSuccess {
        __typename
        billingEmail
        isSuccess
        message
        pinSendMethod
      }
      ... on LinkAccountPinResendProblem {
        __typename
        code
        isSuccess
        invalidInputs {
          message
          field
        }
        message
      }
    }
  }
`);

const validatePinMutation = gql(`
mutation ValidatePin($phoneNumber: String!, $pinInput: String!) {
    validatePin(phoneNumber: $phoneNumber, pin: $pinInput) {
      ... on LinkAccountSuccess {
        __typename
        billingEmail
        isSuccess
        message
        pinSendMethod
        comverseAccountId
      }
      ... on LinkAccountProblem {
        __typename
        code
        isSuccess
        invalidInputs {
          message
          field
        }
        message
      }
    }
  }
`);

export {
  linkComverseAccountMutation,
  linkComverseAccountResendPinMutation,
  validatePinMutation,
};
