import { useState } from "react";
import IframedChatDialog from "./IframedChatDialog";

function IframedChat() {
  const [shouldShowChat, setShouldShowChat] = useState<boolean>(false);

  function handleChatDialog() {
    setShouldShowChat(!shouldShowChat);
  }

  return (
    <>
      <div
        aria-hidden="true"
        className="rounded-t-lg bg-primary px-4 py-1 sm:px-8"
        id="buttonChat"
        onClick={() => {
          handleChatDialog();
        }}
      >
        Live Chat
      </div>
      {shouldShowChat ? (
        <IframedChatDialog
          onClose={handleChatDialog}
          shouldShowChat={shouldShowChat}
        />
      ) : null}
    </>
  );
}

export default IframedChat;
