import type { JSX, ReactElement } from "react";
import { useRouter } from "next/router";
import { useQueryClient } from "@tanstack/react-query";
import { fromPromise } from "xstate";
import { createActorContext } from "@xstate/react";
import ComverseAccountAndServicesMachineContext from "../comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";
import updateNicknameMutation from "./mutation";
import { useGraphQLContext } from "@/components/contexts/GraphQLContext";
import { useMachineUtil } from "@/components/contexts/MachineUtil";
import nicknameMachine from "@/components/machines/nicknameMachine/nicknameMachine";
import { useToast } from "@/components/contexts/toast";

const NicknameMachineContext = createActorContext(nicknameMachine);

function NicknameMachineLogicComponent({
  children,
}: {
  children: ReactElement;
}) {
  const { graphqlClient } = useGraphQLContext();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { inspect } = useMachineUtil();
  const { showToast } = useToast();

  const comverseAccountAndServicesActor =
    ComverseAccountAndServicesMachineContext.useActorRef();

  const nicknameMachineImplementation = nicknameMachine.provide({
    actions: {
      displayErrorMessage: () => {
        showToast({
          message: "Something went wrong. Please try again.",
        });
      },
      redirectToLinkedAccounts: () => {
        if (router.pathname === "/user/link-account/enter-nickname") {
          return;
        }

        void router.replace("/user/settings/linked-accounts");
      },
      redirectToUpdateNicknamePage: () => {
        if (router.pathname === "/user/link-account/enter-nickname") {
          return;
        }

        void router.replace("/user/settings/account-nickname");
      },
      refetchAccounts: () => {
        showToast({
          backgroundColour: "primary",
          message: "Nickname updated",
        });

        comverseAccountAndServicesActor.send({ type: "REFETCH_ACCOUNTS" });
      },
    },
    actors: {
      updateNickname: fromPromise(
        async ({
          input,
        }: {
          input: { comverseAccountId: string; nickname: string };
        }) => {
          const { comverseAccountId, nickname } = input;

          await queryClient.invalidateQueries({
            queryKey: ["updateNickname", comverseAccountId, nickname],
          });

          await queryClient.fetchQuery({
            queryFn: async () => {
              return graphqlClient.request(updateNicknameMutation, {
                comverseAccountId,
                nickname,
              });
            },
            queryKey: ["updateNickname", comverseAccountId, nickname],
          });
        },
      ),
    },
  });

  return (
    <NicknameMachineContext.Provider
      logic={nicknameMachineImplementation}
      options={{
        inspect,
      }}
    >
      {children}
    </NicknameMachineContext.Provider>
  );
}

function NicknameMachineContextInjector({
  children,
}: {
  children: JSX.Element;
}) {
  const router = useRouter();

  const nicknamePages = [
    "/user/settings/account-nickname",
    "/user/settings/linked-accounts",
    "/user/link-account/enter-nickname",
  ];

  return nicknamePages.includes(router.pathname) ? (
    <NicknameMachineLogicComponent>{children}</NicknameMachineLogicComponent>
  ) : (
    children
  );
}

export { NicknameMachineLogicComponent, NicknameMachineContextInjector };
export default NicknameMachineContext;
