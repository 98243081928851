import { useRouter } from "next/router";
import { createActorContext } from "@xstate/react";
import { type JSX } from "react";
import { fromCallback, fromPromise } from "xstate";
import { useMsal } from "@azure/msal-react";
import { CircularProgress } from "@jt/ui";
import jtIdMachine from "./jtIdMachine";
import deleteJTIDMutation from "./mutation";
import { useMachineUtil } from "@/components/contexts/MachineUtil";
import useAuth from "@/useAuth";
import config from "@/config";
import { useGraphQLContext } from "@/components/contexts/GraphQLContext";
import { useToast } from "@/components/contexts/toast";

const JtIdMachineContext = createActorContext(jtIdMachine);

function JtIdMachineLogicComponent({ children }: { children: JSX.Element }) {
  const { graphqlClient } = useGraphQLContext();
  const { inspect } = useMachineUtil();
  const router = useRouter();
  const { user, isLoading } = useAuth();
  const { showToast } = useToast();
  const { instance } = useMsal();

  if (isLoading) return <CircularProgress />;

  function checkAndRedirectToLoginOrHomepage() {
    const { pathname } = router;

    const searchParameters = new URLSearchParams(window.location.search);

    const isPrivateUrl = pathname.toLocaleLowerCase().includes("/user/");

    const redirectUrl = searchParameters.get("redirect");
    const isRedirectUrl = redirectUrl !== null;

    switch (true) {
      case !config.app.isNativeApp && isPrivateUrl && !isRedirectUrl: {
        window.location.href = `/?redirect=${redirectUrl}`;
        break;
      }

      case config.app.isNativeApp && isPrivateUrl: {
        window.location.href = "/";
        break;
      }

      default: {
        break;
      }
    }
  }

  const jtIdMachineImplementation = jtIdMachine.provide({
    actions: {
      redirectToLogin() {
        const logoutRequest = {
          account: instance.getActiveAccount(),
          idTokenHint: instance.getActiveAccount()?.idToken,
          postLogoutRedirectUri: config.app.isNativeApp ? "/logout" : "/",
        };

        void instance.logoutRedirect(logoutRequest);
      },
      showErrorToast() {
        showToast({
          message: "Something went wrong. Please try again.",
        });
      },
      showSuccessToast() {
        showToast({
          backgroundColour: "primary",
          message: "Success. You will be redirected shortly...",
        });
      },
    },
    actors: {
      checkUserLoggedIn: fromCallback(({ sendBack }) => {
        const url = router.asPath;
        const searchParameters = new URLSearchParams(window.location.search);
        const offerId = searchParameters.get("offerId");
        const island = searchParameters.get("island");
        const isEsimFlow = url.toLowerCase().includes("/buy/esim");
        const isUserLoggedIn = user !== null;
        const hasOfferIdAndIsland = offerId !== null && island !== null;

        switch (true) {
          case isUserLoggedIn && isEsimFlow: {
            sendBack({
              data: user,
              type: "LOGGED_IN",
            });
            break;
          }

          case !isUserLoggedIn && isEsimFlow && hasOfferIdAndIsland: {
            void router.push({
              pathname: "/buy/esim",
              query: { island, offerId },
            });
            break;
          }

          case !isUserLoggedIn && !isEsimFlow: {
            checkAndRedirectToLoginOrHomepage();
            break;
          }

          case isUserLoggedIn && !isEsimFlow: {
            const hasPreviouslyLoggedIn = localStorage.getItem(
              "hasPreviouslyLoggedIn",
            );

            if (hasPreviouslyLoggedIn === null) {
              localStorage.setItem("hasPreviouslyLoggedIn", "true");
            }

            sendBack({
              data: user,
              type: "LOGGED_IN",
            });
            break;
          }

          default: {
            checkAndRedirectToLoginOrHomepage();
            break;
          }
        }
      }),
      deleteJTID: fromPromise(async () => {
        return graphqlClient.request(deleteJTIDMutation);
      }),
    },
  });

  return (
    <JtIdMachineContext.Provider
      logic={jtIdMachineImplementation}
      options={{
        inspect,
      }}
    >
      {children}
    </JtIdMachineContext.Provider>
  );
}

export { JtIdMachineContext, JtIdMachineLogicComponent };
