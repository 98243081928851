import type { Contact } from "@/nativeAppUtils";
import { findReplaceTargetByPhoneName } from "@/nativeAppUtils";
import { type Usage, UsageType } from "@/__generated__/graphql";

function sortByDateAscending<T>(array: Array<T>, property: keyof T): void {
  array.sort((a, b) => {
    const aValue = a[property];
    const bValue = b[property];

    if (typeof aValue === "string" && typeof bValue === "string") {
      const dateA = new Date(aValue).getTime();
      const dateB = new Date(bValue).getTime();

      return dateB - dateA;
    }

    return 0;
  });
}

function sortByDateDescending<T>(array: Array<T>, property: keyof T): void {
  array.sort((a, b) => {
    const aValue = a[property];
    const bValue = b[property];

    if (typeof aValue === "string" && typeof bValue === "string") {
      const dateA = new Date(aValue).getTime();
      const dateB = new Date(bValue).getTime();

      return dateA - dateB;
    }

    return 0;
  });
}

function toLocalDateString(dateString: string): string {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-UK");
}

function toLocalDateTimeString(dateString: string): string {
  const date = new Date(dateString);

  return date.toLocaleString("en-UK");
}

function toPoundCurrency(amount: number): string {
  return `£${amount.toLocaleString("en-UK", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
}

function joinClassNames(...classes: Array<boolean | string>): string {
  return classes.filter(Boolean).join(" ");
}

function formatMsisdnToMobile(msisdn: string, hasSpace = false): string {
  let mobile = msisdn;

  if (mobile) {
    const hasCountryPrefix = mobile.startsWith("44");

    if (hasCountryPrefix) {
      mobile = mobile.slice(2);
    }

    const hasMobilePrefix = msisdn.startsWith("0");

    if (!hasMobilePrefix) {
      mobile = `0${mobile}`;
    }

    if (hasSpace) {
      mobile = mobile.replace(/^(?:\d{5})(?:\d{3})(?:\d{3})$/, (match) => {
        return `${match.slice(0, 5)} ${match.slice(5, 8)} ${match.slice(8)}`;
      });
    }

    return mobile;
  } else {
    return "";
  }
}

function formatMsisdnToMobileWithCountryPrefix(msisdn: string): string {
  let mobile = msisdn;

  if (mobile) {
    const hasCountryPrefix = mobile.startsWith("44");

    if (hasCountryPrefix) {
      mobile = mobile.slice(2);
    }

    return `+44 ${mobile}`;
  } else {
    return "";
  }
}

function formatUndefinedAsEmptyString(string?: string): string {
  return string ?? "";
}

function formatNumberUndefinedAsEmptyString(number?: number): string {
  return number === undefined ? "" : number.toString();
}

function addThousandsSeparator(value: number): string {
  return value.toLocaleString("en-UK");
}

function capitalizeFirstCharacter(string: string): string {
  let firstCharacter = string.charAt(0);

  firstCharacter = firstCharacter.toUpperCase();

  return firstCharacter + string.slice(1);
}

function formatBytes(bytes: number): string {
  const megabyte = 1024 * 1024;
  const gigabyte = megabyte * 1024;

  if (bytes >= gigabyte) {
    const gb = (bytes / gigabyte).toFixed(2);

    return `${gb} GB`;
  } else {
    const mb = (bytes / megabyte).toFixed(2);

    return `${mb} MB`;
  }
}

/**
 * retrieve n the part from content, parts separator is |
 * @param content
 * @param part
 * @returns part of the description
 */
function extractContentPart(content: string, part: number): string {
  const parts = content.split("|");

  return parts.length > part ? parts[part].trim() : "";
}

function computeItemisationDisplayValue(
  itemisationEntry: Usage,
  phoneContacts: Array<Contact>,
) {
  let phoneNumber = "Unknown";

  phoneNumber =
    itemisationEntry.target === undefined ||
    itemisationEntry.target === null ||
    itemisationEntry.target === ""
      ? itemisationEntry.phoneNumber
      : itemisationEntry.target;

  let displayText = "Unknown";

  switch (true) {
    case itemisationEntry.type === UsageType.Voice ||
      itemisationEntry.type === UsageType.Minutes: {
      displayText = `Call to ${phoneNumber}`;
      break;
    }

    case itemisationEntry.type === UsageType.Mms ||
      itemisationEntry.type === UsageType.Sms: {
      displayText = `Text to ${phoneNumber}`;
      break;
    }

    case itemisationEntry.type === UsageType.Data: {
      displayText = `Mobile Data`;
      break;
    }

    case itemisationEntry.type === UsageType.BroadbandData: {
      displayText = `Broadband Data`;
      break;
    }

    default: {
      displayText = itemisationEntry.description;
      break;
    }
  }

  return findReplaceTargetByPhoneName({
    contacts: phoneContacts,
    currentDisplay: displayText,
    targetNumber: phoneNumber,
  });
}

export {
  addThousandsSeparator,
  capitalizeFirstCharacter,
  extractContentPart,
  joinClassNames,
  formatBytes,
  formatMsisdnToMobile,
  formatMsisdnToMobileWithCountryPrefix,
  formatNumberUndefinedAsEmptyString,
  formatUndefinedAsEmptyString,
  sortByDateAscending,
  sortByDateDescending,
  toLocalDateString,
  toLocalDateTimeString,
  toPoundCurrency,
  computeItemisationDisplayValue,
};
