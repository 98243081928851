import { gql } from "@/__generated__/gql";

const updateNicknameMutation = gql(`
mutation UpdateNickname($comverseAccountId: ComverseId!, $nickname: String!) {
  updateAccountNickname(comverseAccountId: $comverseAccountId, nickname: $nickname) {
    ... on UpdateAccountNicknameSuccess {
      __typename
      isSuccess
      message
    }
    ... on UpdateAccountNicknameProblem {
      __typename
      isSuccess
      message
    }
  }
}
`);

export default updateNicknameMutation;
