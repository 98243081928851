/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { AccountInfo } from "@azure/msal-browser";
import { assertEvent, createMachine, assign } from "xstate";

const machine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCkAuBJCBZAhgYwAsBLAOzADoAZAeyhgnRPICUwBbagN1KgEE881AK4lUAYmYBRAMqSAKgG0ADAF1EoAA7VYRVEWol1IAB6IAzAHYz5MwE5bAJgCMADlsunZgCwA2FwFYAGhAAT0QfMyVyByULCIcvFwClWycAXzTgtExcQlIKGjpIRhZ2Lh5+QRFUckwAGzAxAHkABUkAOQB9LCaAEV5KZTUkEC0dPQMjUwRvf2j-ANsLfwcfJxiXYLCEFwcLci841zMzZzWzFwysjGx8YjIqWnoS1g5uEj4BYVFyLGoIHB1JoaMBMeqNKQ9ABqkk6vAAwvCmgBVdqKVRGMa6fSGEbTLx7cgWFx+DwxfxeVxbRBOWJOcg+JSuJS7dwWLzeK4gbK3PIPQrPJivcofSrfGp-AFAkFCsqcMBi6rSIQCOCwMTGWCoHCoCg4ABmuoATgAKSJKJQASjEPNy9wKT2KsreFS+1V+-0BwNBpTeCrdomVqtgsCGmO02MmeMQXi8UU8Sgipzs-n8TOpCCc-jiRMsFO8Pl8JK5tru+UeRQYzpFnyqP0lXplLBEJB4wvlitEWCE2omJDEEAMFFInGoAGsKBAwA1dcg5OhemGRli+1MY7HyCtbImHA5-E5DlYvBnPE4fNEWZYfP5bD4fO50pluTc7eWBU7fTXOxLPdKfcwWzbOV-TrVBu17HExDAI0jWoI1yA0OodX1OC2HIKcZzAOcFyXTQI1XaNMysGwzFcZYvHcBYWQsE8sxccgXCUBxvCWJYlCvEsXzLflHQgJoezESgmgAcWEyRek6dB2lw0Z8JxNcZhiBj7F3PcLFiVMfBPMw5j8UjC2cLMmVODInxIf44CMUs+TAcNxnkwiAFpXAZPx1i8NMDycVIzAzRy91sGwPIsW8z1sSkXGWTicm4h1K0YOzI1xUBpgcQKTjIilKMYyKM1OLwiViAyLUsEKHGi3l7QrQVP3eWtxUSgiUsQCx9gyyKsoCHKaNCRAKQcQq4jMQtb0SZwKtfHj4urOrv1qCAGkahzmpmFwCsMiw9j2JkVicDNNoKuwKP8E4rGWA8Jti6qP3bV1QI9KVvWS2T7KjFb1jmdryOy6iM1Sc9vE8Yb7xZON-EumzrqrWq7vFB7Gx9cElrekwYySchvNIq9EgWE5fN6zMSoYykTlWXYSTiCGqvfaHbtFAMf0ept2xA8UgzwNVkee-EWRsW89wo2kbyYk8OSiC4FjS1ZNoPLwqbfXiXjlWH3QbP8hUAj4We-cCdWWl6koUi56TS3x+dTWMTlo-xzxWNwC1vRw73lqb6H41AuYU5loicJwLCMgk9mzfHtmc-3iaoplWrsP2LFMtIgA */
    context: {},
    id: "JtIdMachine",
    initial: "LoggedOut",
    states: {
      LoggedIn: {
        description:
          "Inside here we can have parallel machines relating to our user account that must occur in a signed in context.\n\nAt the time of writing this is only RemovingAccount, but the assumption is that in the future we may have more things we want to do, and those can live alongside RemovingAccount.",
        states: {
          RemovingAccount: {
            initial: "Idle",
            on: {
              RESET: {
                target: "#JtIdMachine.LoggedIn.RemovingAccount.Idle",
              },
            },
            states: {
              Idle: {
                on: {
                  OPEN_MODAL: {
                    target: "ModalOpen",
                  },
                },
              },
              ModalOpen: {
                initial: "Idle",
                states: {
                  Idle: {
                    on: {
                      REMOVE_ACCOUNT: {
                        target: "RunningRemoveAccountMutation",
                      },
                    },
                  },
                  RemoveAccountSuccess: {
                    after: {
                      3000: {
                        actions: [
                          {
                            type: "clearLocalStorage",
                          },
                          {
                            type: "redirectToLogin",
                          },
                        ],
                      },
                    },
                    entry: [
                      {
                        type: "showSuccessToast",
                      },
                    ],
                  },
                  RunningRemoveAccountMutation: {
                    invoke: {
                      id: "deleteJTID",
                      input: {},
                      onDone: {
                        target: "RemoveAccountSuccess",
                      },
                      onError: {
                        actions: [
                          {
                            type: "showErrorToast",
                          },
                        ],
                        target: "Idle",
                      },
                      src: "deleteJTID",
                    },
                  },
                },
              },
            },
          },
        },
        type: "parallel",
      },
      LoggedOut: {
        description:
          "Initial state for the machine.\n\nIn the invoked actor we check if the user has valid tokens for auth and if the do then we move to the Logged In State. If there is a redirect query param in the URL then we redirect to that URL.\n\nIf the user is not logged in then we should take the user to the login page, with a return URL that  includes a redirect parameter, so that once you've finished the login process you go back to where you were initially trying to go.",
        invoke: {
          id: "checkUserLoggedIn",
          src: "checkUserLoggedIn",
        },
        on: {
          LOGGED_IN: {
            actions: {
              type: "assignUserIdToContext",
            },
            target: "LoggedIn",
          },
        },
      },
    },
    types: {
      context: {} as { userId?: string },
      events: {} as
        | { type: "LOGGED_IN"; data: AccountInfo }
        | { type: "OPEN_MODAL" }
        | { type: "REMOVE_ACCOUNT" }
        | { type: "RESET" },
    },
  },
  {
    actions: {
      assignUserIdToContext: assign({
        userId: ({ event }) => {
          assertEvent(event, "LOGGED_IN");

          return event.data.localAccountId;
        },
      }),
      clearLocalStorage: () => {
        window.localStorage.clear();
      },
    },
  },
);

export default machine;
