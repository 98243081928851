// eslint-disable-next-line import/no-named-as-default
import pino from "pino";

// need to check default level because build fails because of "NEXT_PUBLIC_NEXT_PUBLIC_LOG_LEVEL_VALUE" in the .env.local
const validLogLevels = new Set([
  "fatal",
  "error",
  "warn",
  "info",
  "debug",
  "trace",
  "silent",
]);
const defaultLogLevel = "trace";

const logLevel = validLogLevels.has(process.env.NEXT_PUBLIC_LOG_LEVEL ?? "")
  ? process.env.NEXT_PUBLIC_LOG_LEVEL
  : defaultLogLevel;

const logger = pino({
  browser: {
    asObject: true,
  },
  // One of 'fatal', 'error', 'warn', 'info', 'debug', 'trace' or 'silent'.
  level: logLevel,
});

export default logger;
