import { gql } from "@/__generated__";

const deleteJTIDMutation = gql(`
  mutation DeleteJTID {
    deleteAuthAccount {
      isSuccess
      message
    }
  }
`);

export default deleteJTIDMutation;
