import { useState } from "react";
import { createPortal } from "react-dom";
import { FaCloseRounded } from "@jt/ui";

/* getFeedback need both allow-same-origin allow-scripts, but eslint rule does not allow, therefore disable this rule */
/* eslint-disable react/iframe-missing-sandbox */
function Feedback() {
  const [canFeedback, setCanFeedback] = useState(false);

  return (
    <>
      <div
        aria-hidden="true"
        className="origin-bottom-right -rotate-90 cursor-pointer rounded-t-lg bg-jt-blue px-4 py-1 text-xs font-bold text-white sm:px-8 sm:text-lg"
        onClick={() => {
          setCanFeedback(true);
        }}
      >
        Feedback
      </div>
      {canFeedback
        ? createPortal(
            <div className="fixed left-0 top-0 z-50 flex size-full items-center justify-center">
              <div
                aria-hidden="true"
                className="absolute left-0 top-0 z-10 size-full bg-black/50"
                onClick={() => {
                  setCanFeedback(false);
                }}
              />
              <div className="relative z-20 h-[70vh] w-full max-w-[600px] sm:h-[500px]">
                <iframe
                  className="size-full"
                  src="https://jtglobal.getfeedback.com/e/3g05Lk8E"
                  title="Feedback"
                />
                <div
                  aria-hidden="true"
                  className="absolute -top-6 right-2 cursor-pointer text-white sm:right-6 sm:top-4"
                  onClick={() => {
                    setCanFeedback(false);
                  }}
                >
                  <FaCloseRounded />
                </div>
              </div>
            </div>,
            document.body,
          )
        : null}
    </>
  );
}
export default Feedback;
