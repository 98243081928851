import { Dialog } from "@jt/ui";
import { useState, useEffect, useRef } from "react";
import useAuth from "@/useAuth";
import { formatUndefinedAsEmptyString } from "@/utils";
import ComverseAccountsAndServicesContext from "@/components/machines/comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";
import useSelectedServiceType from "@/components/machines/comverseAccountAndServicesMachine/useSelectedServiceType";

interface TalkativeEventData {
  chat: {
    status: string;
  };
}

interface IframedChatDialogProperties {
  shouldShowChat?: boolean;
  onClose?: () => void;
}

/* eslint-disable react/iframe-missing-sandbox */
function IframedChatDialog({
  shouldShowChat = true,
  onClose,
}: IframedChatDialogProperties) {
  const [shouldShowDialog, setShouldShowDialog] =
    useState<boolean>(shouldShowChat);
  const { user } = useAuth();
  const iframeReference = useRef<HTMLIFrameElement>(null);
  const serviceType = useSelectedServiceType();
  const comverseAccountAndServicesState =
    ComverseAccountsAndServicesContext.useSelector((snapshot) => {
      return snapshot.context;
    });
  const { selectedComverseAccountId, selectedService } =
    comverseAccountAndServicesState;

  const appHost =
    window.location.hostname === "localhost"
      ? "http://localhost:3000"
      : `https://${window.location.hostname}`;

  const userInfoParameters: URLSearchParams = new URLSearchParams();

  if (user) {
    const displayName = [
      formatUndefinedAsEmptyString(user.idTokenClaims?.given_name as string),
      formatUndefinedAsEmptyString(user.idTokenClaims?.family_name as string),
    ]
      .join(" ")
      .trim();

    userInfoParameters.append("email", user.username);
    userInfoParameters.append("name", displayName);

    if (
      serviceType === "Prepaid" &&
      selectedService?.phoneNumber !== undefined
    ) {
      userInfoParameters.append("phoneNumber", selectedService.phoneNumber);
    } else if (
      serviceType === "Postpaid" &&
      selectedComverseAccountId !== undefined
    ) {
      userInfoParameters.append("accountNumber", selectedComverseAccountId);
    }
  }

  useEffect(() => {
    // Add event listener to catch messages from the iframe
    function handleMessage(event: MessageEvent<TalkativeEventData>) {
      if (
        iframeReference.current !== null &&
        event.source === iframeReference.current.contentWindow &&
        event.origin === appHost &&
        event.data.chat.status === "closed"
      ) {
        handleCloseClick();
      }
    }

    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmountshow to use useRef in react
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function handleCloseClick() {
    setShouldShowDialog(!shouldShowDialog);
    if (onClose) {
      onClose();
    }
  }

  return (
    <Dialog
      dialogClassName="!bg-transparent !p-0"
      isOpen={shouldShowChat}
      onClose={handleCloseClick}
    >
      <iframe
        allowFullScreen
        className="h-[calc(100vh-170px)] max-h-[800px] w-full rounded-3xl"
        ref={iframeReference}
        referrerPolicy="no-referrer-when-downgrade"
        src={`${appHost}/iframechat.html?${userInfoParameters.toString()}`}
        title="JT Global Chat"
      />
    </Dialog>
  );
}

export default IframedChatDialog;
