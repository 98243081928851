import type { StateValueMap } from "xstate";
import ComverseAccountAndServicesMachineContext from "@/components/machines/comverseAccountAndServicesMachine/ComverseAccountAndServicesMachineContext";

function isStateValueMap(value: unknown): value is StateValueMap {
  return typeof value === "object" && value !== null;
}

function isServiceConfiguration(value: unknown): value is ServiceConfiguration {
  return (
    typeof value === "string" &&
    [
      "MultiplePrepaid",
      "OnlyPostpaid",
      "PreAndPostPaid",
      "SinglePrepaid",
    ].includes(value)
  );
}

function useServicesConfiguration(): ServiceConfiguration {
  const state = ComverseAccountAndServicesMachineContext.useSelector((s) => s);

  // Check state is a map and matches the expected path
  if (
    isStateValueMap(state.value) &&
    isStateValueMap(state.value.ServicesLoaded) &&
    isServiceConfiguration(state.value.ServicesLoaded.ServiceConfiguration)
  ) {
    return state.value.ServicesLoaded.ServiceConfiguration;
  }

  return "unknown";
}

export type ServiceConfiguration =
  | "MultiplePrepaid"
  | "OnlyPostpaid"
  | "PreAndPostPaid"
  | "SinglePrepaid"
  | "unknown";
export default useServicesConfiguration;
